import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <a id="spell-list"></a><h2>Spell List</h2>
    <a id="cleric-domains"></a><h3>CLERIC DOMAINS</h3>
    <table border="0" cellPadding="0" cellSpacing="0" style={{
      "width": "100%"
    }}>
      <tbody>
        <tr>
          <td></td>
        </tr>
      </tbody>
    </table>
    <table style={{
      "width": "100%"
    }}>
      <tr>
        <td style={{
          "verticalAlign": "top"
        }}>
          <a style={{
            "color": "#579eb6"
          }} href="#air">Air</a>
          <a style={{
            "color": "#579eb6"
          }} href="#animal">Animal</a>
          <a style={{
            "color": "#579eb6"
          }} href="#artifice">Artifice</a>
          <a style={{
            "color": "#579eb6"
          }} href="#chaos">Chaos</a>
          <a style={{
            "color": "#579eb6"
          }} href="#charm">Charm</a>
          <a style={{
            "color": "#579eb6"
          }} href="#community">Community</a>
        </td>
        <td style={{
          "verticalAlign": "top"
        }}>
          <a style={{
            "color": "#579eb6"
          }} href="#creation">Creation</a>
          <a style={{
            "color": "#579eb6"
          }} href="#darkness">Darkness</a>
          <a style={{
            "color": "#579eb6"
          }} href="#death">Death</a>
          <a style={{
            "color": "#579eb6"
          }} href="#destruction">Destruction</a>
          <a style={{
            "color": "#579eb6"
          }} href="#earth">Earth</a>
          <a style={{
            "color": "#579eb6"
          }} href="#evil">Evil</a>
        </td>
        <td style={{
          "verticalAlign": "top"
        }}>
          <a style={{
            "color": "#579eb6"
          }} href="#fire">Fire</a>
          <a style={{
            "color": "#579eb6"
          }} href="#glory">Glory</a>
          <a style={{
            "color": "#579eb6"
          }} href="#good">Good</a>
          <a style={{
            "color": "#579eb6"
          }} href="#healing">Healing</a>
          <a style={{
            "color": "#579eb6"
          }} href="#knowledge">Knowledge</a>
          <a style={{
            "color": "#579eb6"
          }} href="#law">Law</a>
        </td>
        <td style={{
          "verticalAlign": "top"
        }}>
          <a style={{
            "color": "#579eb6"
          }} href="#liberation">Liberation</a>
          <a style={{
            "color": "#579eb6"
          }} href="#luck">Luck</a>
          <a style={{
            "color": "#579eb6"
          }} href="#madness">Madness</a>
          <a style={{
            "color": "#579eb6"
          }} href="#magic">Magic</a>
          <a style={{
            "color": "#579eb6"
          }} href="#nobility">Nobility</a>
          <a style={{
            "color": "#579eb6"
          }} href="#plant">Plant</a>
        </td>
        <td style={{
          "verticalAlign": "top"
        }}>
          <a style={{
            "color": "#579eb6"
          }} href="#protection">Protection</a>
          <a style={{
            "color": "#579eb6"
          }} href="#repose">Repose</a>
          <a style={{
            "color": "#579eb6"
          }} href="#rune">Rune</a>
          <a style={{
            "color": "#579eb6"
          }} href="#skalykind">Skalykind</a>
          <a style={{
            "color": "#579eb6"
          }} href="#strength">Strength</a>
          <a style={{
            "color": "#579eb6"
          }} href="#sun">Sun</a>
        </td>
        <td style={{
          "verticalAlign": "top"
        }}>
          <a style={{
            "color": "#579eb6"
          }} href="#travel">Travel</a>
          <a style={{
            "color": "#579eb6"
          }} href="#trickery">Trickery</a>
          <a style={{
            "color": "#579eb6"
          }} href="#war">War</a>
          <a style={{
            "color": "#579eb6"
          }} href="#water">Water</a>
          <a style={{
            "color": "#579eb6"
          }} href="#weather">Weather</a>
        </td>
      </tr>
    </table>
    <a id="air"></a><a id="air-domain"></a><h5>AIR DOMAIN</h5>
    <p className="initial"><b>Granted Powers</b>: Turn or destroy earth
creatures as a good cleric turns undead. Rebuke, command, or bolster
air creatures as an evil cleric rebukes undead. Use these abilities a
total number of times per day equal to 3 + your Charisma modifier. This
granted power is a supernatural ability.</p>
    <h6>Air Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscuring-mist">Obscuring
Mist</a>:</td>
          <td> Fog surrounds you.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wind-wall">Wind Wall</a>:</td>
          <td> Deflects arrows, smaller creatures, and gases.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gaseous-form">Gaseous Form</a>:</td>
          <td> Subject becomes insubstantial and can fly slowly.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#air-walk">Air Walk</a>:</td>
          <td> Subject treads on air as if solid (climb at 45-degree
angle).</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-winds">Control Winds</a>:</td>
          <td> Change wind direction and speed.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#chain-lightning">Chain
Lightning</a>:</td>
          <td> 1d6/level damage; 1 secondary bolt/level each deals
half damage.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-weather">Control
Weather</a>:</td>
          <td> Changes weather in local area.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#whirlwind">Whirlwind</a>:</td>
          <td> Cyclone deals damage and can pick up creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#elemental-swarm">Elemental
Swarm</a>*:</td>
          <td> Summons multiple elementals.</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Cast as an air spell only.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="animal"></a><a id="animal-domain"></a><h5>ANIMAL DOMAIN</h5>
    <p className="initial"><b>Granted Powers</b>: You can use speak with
animals once per day as a spell-like ability.</p>
    <p>Add <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a> (nature) to your list of cleric class skills.</p>
    <h6>Animal Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#calm-animals">Calm Animals</a>:</td>
          <td> Calms (2d4 + level) HD of animals.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hold-animal">Hold Animal</a>:</td>
          <td> Paralyzes one animal for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dominate-animal">Dominate
Animal</a>:</td>
          <td> Subject animal obeys silent mental commands.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-iv">Summon
Nature&rsquo;s Ally IV</a>*:</td>
          <td> Calls creature to fight.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#commune-with-nature">Commune
with Nature</a>:</td>
          <td> Learn about terrain for 1 mile/level.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#antilife-shell">Antilife
Shell</a>:</td>
          <td> 10-ft. field hedges out living creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-shapes">Animal Shapes</a>:</td>
          <td> One ally/level polymorphs into chosen animal.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-natures-ally-viii">Summon
Nature&rsquo;s Ally VIII</a>*:</td>
          <td> Calls creature to fight.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shapechange">Shapechange</a><sup>
F</sup>:</td>
          <td> Transforms you into any creature, and change forms
once per round.</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Can only summon animals.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="artifice-domain"></a><h5><a id="artifice"></a>ARTIFICE DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>Gain +4
bonus on Craft checks. The character casts conjuration (creation)
spells at +1 caster level. (Those with access to both the Artifice and
Creation domains cast conjuration [creation] spells at +3 caster level.)<span style={{
      "fontWeight": "bold"
    }}></span>
    <h6>Artifice Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-rope">Animate Rope</a></td>
          <td>Makes a rope move at your command.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wood-shape">Wood Shape</a></td>
          <td>Rearranges wooden objects to suit you.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stone-shape">Stone Shape</a></td>
          <td>Sculpts stone into any shape.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#minor-creation">Minor Creation</a></td>
          <td>Creates one cloth or wood object.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fabricate">Fabricate</a></td>
          <td>Transforms raw materials into finished items.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#major-creation">Major Creation</a></td>
          <td>As minor creation, plus stone and metal.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#hardening">Hardening</a></td>
          <td>Increases hardness of target by 1 per 2 levels.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#true-creation">True Creation</a></td>
          <td>Creates permanent non-magical object.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prismatic-sphere">Prismatic sphere</a></td>
          <td>As prismatic wall, but surrounds on all sides.</td>
        </tr>
      </tbody>
    </table>
    <a id="chaos"></a><a id="chaos-domain"></a><h5>CHAOS DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: You cast chaos spells at
+1 caster level.</p>
    <h6>Chaos Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-law">Protection
from Law</a>:</td>
          <td> +2 to AC and saves, counter mind control, hedge out
elementals and outsiders.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shatter">Shatter</a>:</td>
          <td> Sonic vibration damages objects or crystalline
creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-law">Magic
Circle against Law</a>:</td>
          <td> As protection spells, but 10-ft. radius and 10
min./level.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#chaos-hammer">Chaos Hammer</a>:</td>
          <td> Damages and staggers lawful creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-law">Dispel Law</a>:</td>
          <td> +4 bonus against attacks by lawful creatures.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-objects">Animate
Objects</a>:</td>
          <td> Objects attack your foes.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#word-of-chaos">Word of Chaos</a>:</td>
          <td> Kills, confuses, stuns, or deafens nonchaotic subjects.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cloak-of-chaos">Cloak of Chaos</a><sup>
F</sup>:</td>
          <td> +4 to AC, +4 resistance, SR 25 against lawful spells.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-ix">Summon
Monster IX</a>*:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Cast as a chaos spell only.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="charm-domain"></a><h5><a id="charm"></a>CHARM DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>The
character can boost his or her Charisma by 4 points once per day.
Activating this power is a free action. The Charisma increase lasts 1
minute.<span style={{
      "fontWeight": "bold"
    }}></span>
    <h6>Charm Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#charm-person">Charm Person</a></td>
          <td>Makes one person your friend.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#calm-emotions">Calm Emotions</a></td>
          <td>Calms creatures, negating emotion effects.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#suggestion">Suggestion</a></td>
          <td>Compels subject to follow stated course of action.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "#579eb6"
            }} href="spellsHtoL.html#heroism">Heroism</a></td>
          <td>Gives +2 on attack rolls, saves, skill checks.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#charm-monster">Charm Monster</a></td>
          <td>Makes monster believe it is your ally.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#geas-quest">Geas/Quest</a></td>
          <td>As lesser geas, plus it affects any creature.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#insanity">Insanity</a></td>
          <td>Subject suffers continuous <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#confused">confusion</a>.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#demand">Demand</a></td>
          <td>As sending, plus you can send suggestion.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dominate-monster">Dominate Monster</a></td>
          <td>As dominate person, but any creature.</td>
        </tr>
      </tbody>
    </table>
    <a id="community-domain"></a><h5><a id="community"></a>COMMUNITY DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <p className="initial"><b>Granted Power: </b>Use calm emotions as a
spell-like ability once per day. Gain a +2 competence bonus on
Diplomacy checks.<b></b></p>
    <h6>Community Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bless">Bless</a>
          </td>
          <td>Allies gain +1 on attack rolls and saves against fear.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#status">Status</a></td>
          <td>Monitors condition, position of allies.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prayer">Prayer</a></td>
          <td>Allies +1 bonus on most rolls, enemies &ndash;1 penalty.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#status-greater">Greater Status</a></td>
          <td>Monitors condition, position of allies.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#telepathic-bond">Telepathic Bond</a></td>
          <td>Link lets allies communicate.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heroes-feast">Heroes&rsquo; Feast</a></td>
          <td>Food for one creature/level cures and grants combat
bonuses.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#refuge">Refuge</a></td>
          <td>Alters item to transport its possessor to you.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sympathy">Sympathy</a></td>
          <td>Object or location attracts certain creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-heal">Mass Heal</a></td>
          <td>As heal, but with several subjects.</td>
        </tr>
      </tbody>
    </table>
    <a id="creation-domain"></a><h5><a id="creation"></a>CREATION DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>Cast
conjuration (creation) spells at +2 caster level. (Those with access to
both the Artifice and Creation domains cast conjuration (creation)
spells at +3 caster level.)<span style={{
      "fontWeight": "bold"
    }}></span>
    <h6>Creation Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-water">Create Water</a></td>
          <td>Creates 2 gallons/level of pure water.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#minor-image">Minor Image</a></td>
          <td>As silent image, plus some sound.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-food-and-water">Create Food and Water</a></td>
          <td>Feeds three humans (or one horse)/level.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#minor-creation">Minor Creation</a></td>
          <td>Creates one cloth or wood object.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#major-creation">Major Creation</a></td>
          <td>As minor creation, plus stone and metal.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heroes-feast">Heroes&rsquo; Feast</a></td>
          <td>Food for one creature/level cures and grants combat
bonuses.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#permanent-image">Permanent Image</a></td>
          <td>Includes sight, sound, and smell.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#true-creation">True Creation</a></td>
          <td>Creates permanent nonmagical object.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#genesis">Genesis</a></td>
          <td>Creates a small demiplane.</td>
        </tr>
      </tbody>
    </table>
    <a id="darkness-domain"></a><h5><a id="darkness"></a>DARKNESS DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>Free
Blind-Fight feat.
    <h6>Darkness Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscuring-mist">Obscuring Mist</a>
          </td>
          <td>Fog surrounds you.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blindness">Blindness</a></td>
          <td>Makes subject <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinded.</a></td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#blacklight">Blacklight</a></td>
          <td>Creats magical darkness that the caster can see through.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#armor-of-darkness">Armor of Darkness</a></td>
          <td>Shroud obscures caster and grants darkvison and
deflection bonus to AC.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-v">Summon monster V*</a></td>
          <td>Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prying-eyes">Prying Eyes</a></td>
          <td>1d4 +1/level floating eyes scout for you.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#nightmare">Nightmare</a></td>
          <td>Sends vision dealing 1d10 damage, fatigue.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#power-word-blind">Power Word, Blind</a></td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">Blinds</a> creature with
200 hp or less.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#power-word-kill">Power Word, Kill</a></td>
          <td>Kills one creature with 100 hp or less.</td>
        </tr>
        <tr>
          <td colSpan="2" rowSpan="1">*<span style={{
              "fontStyle": "italic"
            }}>only summons 1d3 shadows</span></td>
        </tr>
      </tbody>
    </table>
    <a id="death"></a><a id="death-domain"></a><h5>DEATH DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: You may use a death
touch once per day. Your death touch is a supernatural ability that
produces a death effect. You must succeed on a melee touch attack
against a living creature (using the rules for touch spells). When you
touch, roll 1d6 per cleric level you possess. If the total at least
equals the creature&rsquo;s current hit points, it dies (no save).</p>
    <h6>Death Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cause-fear">Cause Fear</a>:</td>
          <td> One creature of 5 HD or less flees for 1d4 rounds.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#death-knell">Death Knell</a>:</td>
          <td> Kill <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#dying">dying</a> creature and gain 1d8
temporary hp, +2 to
Str, and +1 caster level.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-dead">Animate Dead</a><sup>
M</sup>:</td>
          <td> Creates undead skeletons and zombies.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#death-ward">Death Ward</a>:</td>
          <td> Grants immunity to death spells and negative energy
effects.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#slay-living">Slay Living</a>:</td>
          <td> Touch attack kills subject.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-undead">Create Undead</a><sup>
M</sup>:</td>
          <td> Create ghouls, ghasts, mummies, or mohrgs.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#destruction">Destruction</a><sup>
F</sup>:</td>
          <td> Kills subject and destroys remains.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-greater-undead">Create
Greater Undead</a><sup> M</sup>:</td>
          <td> Create shadows, wraiths, spectres, or devourers.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wail-of-the-banshee">Wail
of the Banshee</a>:</td>
          <td> Kills one creature/level.</td>
        </tr>
      </tbody>
    </table>
    <a id="destruction"></a><a id="destruction-domain"></a><h5>DESTRUCTION DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: You gain the smite
power, the supernatural ability to make a single melee attack with a +4
bonus on attack rolls and a bonus on damage rolls equal to your cleric
level (if you hit). You must declare the smite before making the
attack. This ability is usable once per day.</p>
    <h6>Destruction Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#inflict-light-wounds">Inflict
Light Wounds</a>:</td>
          <td> Touch attack, 1d8 damage +1/level (max +5).</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shatter">Shatter</a>:</td>
          <td> Sonic vibration damages objects or crystalline
creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#contagion">Contagion</a>:</td>
          <td> Infects subject with chosen disease.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#inflict-critical-wounds">Inflict
Critical Wounds</a>:</td>
          <td> Touch attack, 4d8 damage +1/level (max +20).</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-inflict-light-wounds">Inflict
Light Wounds, Mass</a>:</td>
          <td> Deals 1d8 damage +1/level to any creatures.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#harm">Harm</a>:</td>
          <td> Deals 10 points/level damage to target.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#disintegrate">Disintegrate</a>:</td>
          <td> Makes one creature or object vanish.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#earthquake">Earthquake</a>:</td>
          <td> Intense tremor shakes 5-ft./level radius.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#implosion">Implosion</a>:</td>
          <td> Kills one creature/round.</td>
        </tr>
      </tbody>
    </table>
    <a id="earth"></a><a id="earth-domain"></a><h5>EARTH DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: Turn or destroy air
creatures as a good cleric turns undead. Rebuke, command, or bolster
earth creatures as an evil cleric rebukes undead. Use these abilities a
total number of times per day equal to 3 + your Charisma modifier. This
granted power is a supernatural ability.</p>
    <h6>Earth Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-stone">Magic Stone</a>:</td>
          <td> Three stones become +1 projectiles, 1d6 +1 damage.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#soften-earth-and-stone">Soften
Earth and Stone</a>:</td>
          <td> Turns stone to clay or dirt to sand or mud.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stone-shape">Stone Shape</a>:</td>
          <td> Sculpts stone into any shape.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spike-stones">Spike Stones</a>:</td>
          <td> Creatures in area take 1d8 damage, may be lowed.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-stone">Wall of Stone</a>:</td>
          <td> Creates a stone wall that can be shaped.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stoneskin">Stoneskin</a><sup> M</sup>:</td>
          <td> Ignore 10 points of damage per attack.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#earthquake">Earthquake</a>:</td>
          <td> Intense tremor shakes 5-ft./level radius.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#iron-body">Iron Body</a>:</td>
          <td> Your body becomes living iron.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#elemental-swarm">Elemental
Swarm</a>*:</td>
          <td> Summons multiple elementals.</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Cast as an earth spell only.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="evil"></a><a id="evil-domain"></a><h5>EVIL DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: You cast evil spells at
+1 caster level.</p>
    <h6>Evil Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-good">Protection
from Good</a>:</td>
          <td> +2 to AC and saves, counter mind control, hedge out
elementals and outsiders.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#desecrate">Desecrate</a><sup>
M</sup>:</td>
          <td> Fills area with negative energy, making undead
stronger.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-good">Magic
Circle against Good</a>:</td>
          <td> As protection spells, but 10-ft. radius and 10
min./level.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#unholy-blight">Unholy Blight</a>:</td>
          <td> Damages and sickens good creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-good">Dispel Good</a>:</td>
          <td> +4 bonus against attacks by good creatures.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#create-undead">Create Undead</a><sup>
M</sup>:</td>
          <td> Create ghouls, ghasts, mummies, or mohrgs.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blasphemy">Blasphemy</a>:</td>
          <td> Kills, paralyzes, weakens, or dazes nonevil subjects.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#unholy-aura">Unholy Aura</a><sup>
F</sup>:</td>
          <td> +4 to AC, +4 resistance, SR 25 against good spells.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-ix">Summon
Monster IX</a>*:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Cast as an evil spell only.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="fire"></a><a id="fire-domain"></a><h5>FIRE DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: Turn or destroy water
creatures as a good cleric turns undead. Rebuke, command, or bolster
fire creatures as an evil cleric rebukes undead. Use these abilities a
total number of times per day equal to 3 + your Charisma modifier. This
granted power is a supernatural ability.</p>
    <h6>Fire Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#burning-hands">Burning Hands</a>:</td>
          <td> 1d4/level fire damage (max 5d4).</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#produce-flame">Produce Flame</a>:</td>
          <td> 1d6 damage +1/ level, touch or thrown.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#resist-energy">Resist Energy</a>*:</td>
          <td> Ignores 10 (or more) points of damage/attack from
specified energy type.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-fire">Wall of Fire</a>:</td>
          <td> Deals 2d4 fire damage out to 10 ft. and 1d4 out to 20
ft. Passing through wall deals 2d6 damage +1/level.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-shield">Fire Shield</a>:</td>
          <td> Creatures attacking you take fire damage; you&rsquo;re
protected from heat or cold.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-seeds">Fire Seeds</a>:</td>
          <td> Acorns and berries become grenades and bombs.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-storm">Fire Storm</a>:</td>
          <td> Deals 1d6/level fire damage.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#incendiary-cloud">Incendiary
Cloud</a>:</td>
          <td> Cloud deals 4d6 fire damage/round.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#elemental-swarm">Elemental
Swarm</a>**:</td>
          <td> Summons multiple elementals.</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Resist cold or fire only.</i></td>
        </tr>
        <tr>
          <td colSpan="2">** <i>Cast as a fire spell only.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="glory-domain"></a><h5><a id="glory"></a>GLORY DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <p className="initial"><b>Granted Power: </b>Turn undead with a +2
bonus on the turning check and +1d6 to the turning damage roll.<b></b></p>
    <h6>Glory Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#disrupt-undead">Disrupt Undead</a>
          </td>
          <td>Deals 1d6 damage to one undead.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bless-weapon">Bless Weapon</a></td>
          <td>Weapon strikes true against evil foes.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#searing-light">Searing Light</a></td>
          <td>Ray deals 1d8/two levels damage, more against undead.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#holy-smite">Holy Smite</a></td>
          <td>Damages and <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinds</a> evil creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#holy-sword">Holy Sword</a></td>
          <td>Weapon becomes +5, deals +2d6 damage against evil.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#bolt-of-glory">Bolt of Glory</a></td>
          <td>Fires a magical bolt that deal damage according to the
target's type and origin.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sunbeam">Sunbeam</a></td>
          <td>Beam <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinds</a> and deals 4d6
damage.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#crown-of-glory">Crown of Glory</a></td>
          <td>The caster is imbued with an aura of celestial
authority,
inspiring awe in all lesser creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gate">Gate</a></td>
          <td>Connects two planes for travel or summoning.</td>
        </tr>
      </tbody>
    </table>
    <a id="good"></a><a id="good-domain"></a><h5>GOOD DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: You cast good spells at
+1 caster level.</p>
    <h6>Good Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-evil">Protection
from Evil</a>:</td>
          <td> +2 to AC and saves, counter mind control, hedge out
elementals and outsiders.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#aid">Aid</a>:</td>
          <td> +1 on attack rolls, +1 on saves against fear, 1d8
temporary hp +1/level (max +10).</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-evil">Magic
Circle against Evil</a>:</td>
          <td> As protection spells, but 10-ft. radius and 10
min./level.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#holy-smite">Holy Smite</a>:</td>
          <td> Damages and <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinds</a> evil creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-evil">Dispel Evil</a>:</td>
          <td> +4 bonus against attacks by evil creatures.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blade-barrier">Blade Barrier</a>:</td>
          <td> Wall of blades deals 1d6/level damage.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#holy-word">Holy Word</a><sup>
F</sup>:</td>
          <td> Kills, paralyzes, slows, or deafens nongood subjects.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#holy-aura">Holy Aura</a>:</td>
          <td> +4 to AC, +4 resistance, and SR 25 against evil spells.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-ix">Summon
Monster IX</a>*:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Cast as a good spell only.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="healing"></a><a id="healing-domain"></a><h5>HEALING DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: You cast healing spells
at +1 caster level.</p>
    <h6>Healing Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-light-wounds">Cure Light
Wounds</a>:</td>
          <td> Cures 1d8 damage +1/level (max +5).</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-moderate-wounds">Cure
Moderate Wounds</a>:</td>
          <td> Cures 2d8 damage +1/level (max +10).</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-serious-wounds">Cure
Serious Wounds</a>:</td>
          <td> Cures 3d8 damage +1/level (max +15).</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cure-critical-wounds">Cure
Critical Wounds</a>:</td>
          <td> Cures 4d8 damage +1/level (max +20).</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-light-wounds">Cure
Light Wounds, Mass</a>:</td>
          <td> Cures 1d8 damage +1/level (max +25) for many creatures.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heal">Heal</a>:</td>
          <td> Cures 10 points/level of damage, all diseases and
mental conditions.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#regenerate">Regenerate</a>:</td>
          <td> Subject&rsquo;s severed limbs grow back, cures 4d8
damage
+1/level (max +35).</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#mass-cure-critical-wounds">Cure
Critical Wounds, Mass</a>:</td>
          <td> Cures 4d8 damage +1/level (max +40) for many creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#mass-heal">Heal, Mass</a>:</td>
          <td> As heal, but with several subjects.</td>
        </tr>
      </tbody>
    </table>
    <a id="knowledge"></a><a id="knowledge-domain"></a><h5>KNOWLEDGE DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: Add all <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#knowledge">Knowledge</a>
skills
to your list of cleric class skills.</p>
    <p>You cast divination spells at +1 caster level.</p>
    <h6>Knowledge Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-secret-doors">Detect
Secret Doors</a>:</td>
          <td> Reveals hidden doors within 60 ft.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#detect-thoughts">Detect
Thoughts</a>:</td>
          <td> Allows &ldquo;listening&rdquo; to surface thoughts.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#clairaudience/clairvoyance">Clairaudience/Clairvoyance</a>:</td>
          <td> Hear or see at a distance for 1 min./level.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#divination">Divination</a><sup>
M</sup>:</td>
          <td> Provides useful advice for specific proposed actions.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#true-seeing">True Seeing</a><sup>
M</sup>:</td>
          <td> Lets you see all things as they really are.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#find-the-path">Find the Path</a>:</td>
          <td> Shows most direct way to a location.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#legend-lore">Legend Lore</a><sup>
M F</sup>:</td>
          <td> Lets you learn tales about a person, place, or thing.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#discern-location">Discern
Location</a>:</td>
          <td> Reveals exact location of creature or object.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#foresight">Foresight</a>:</td>
          <td> &ldquo;Sixth sense&rdquo; warns of impending danger.</td>
        </tr>
      </tbody>
    </table>
    <a id="law"></a><a id="law-domain"></a><h5>LAW DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: You cast law spells at
+1 caster level.</p>
    <h6>Law Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-chaos">Protection
from Chaos</a>:</td>
          <td> +2 to AC and saves, counter mind control, hedge out
elementals and outsiders.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#calm-emotions">Calm Emotions</a>:</td>
          <td> Calms creatures, negating emotion effects.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-circle-against-chaos">Magic
Circle against Chaos</a>:</td>
          <td> As protection spells, but 10-ft. radius and 10
min./level.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#orders-wrath">Order&rsquo;s
Wrath</a>:</td>
          <td> Damages and dazes chaotic creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-chaos">Dispel Chaos</a>:</td>
          <td> +4 bonus against attacks by chaotic creatures.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#hold-monster">Hold Monster</a>:</td>
          <td> As hold person, but any creature.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dictum">Dictum</a>:</td>
          <td> Kills, paralyzes, slows, or deafens nonlawful subjects.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shield-of-law">Shield of Law</a><sup>
F</sup>:</td>
          <td> +4 to AC, +4 resistance, and SR 25 against chaotic
spells.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#summon-monster-ix">Summon
Monster IX</a>*:</td>
          <td> Calls extraplanar creature to fight for you.</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Cast as a law spell only.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="liberation-domain"></a><h5><a id="liberation"></a>LIBERATION DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power:</span> The
character gains a +2 morale bonus on all saving throws against
enchantment spells or effects.<span style={{
      "fontWeight": "bold"
    }}></span>
    <h6>Liberation Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-fear">Remove Fear</a>
          </td>
          <td>Suppresses fear or gives +4 on saves against fear for
one subject + one per four levels.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-paralysis">Remove Paralysis</a></td>
          <td>Frees one or more creatures from paralysis or slow
effect.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#remove-curse">Remove Curse</a></td>
          <td>Frees object or person from curse.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#freedom-of-movement">Freedom of Movement</a></td>
          <td>Subject moves normally despite impediments.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#break-enchantment">Break Enchantment</a></td>
          <td>Frees subjects from enchantments, alterations, curses,
and petrification.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#greater-dispel-magic">Greater Dispel Magic</a>
          </td>
          <td>As dispel magic, but up to +20 on check.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#refuge">Refuge</a></td>
          <td>Alters item to transport its possessor to you.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mind-blank">Mind Blank</a></td>
          <td>Subject is immune to mental/emotional magic and scrying.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "#579eb6"
            }} href="spellsFtoG.html#freedom">Freedom</a></td>
          <td>Releases creature from imprisonment.</td>
        </tr>
      </tbody>
    </table>
    <a id="luck"></a><a id="luck-domain"></a><h5>LUCK DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: You gain the power of
good fortune, which is usable once per day. This extraordinary ability
allows you to reroll one roll that you have just made before the DM
declares whether the roll results in success or failure. You must take
the result of the reroll, even if it&rsquo;s worse than the original
roll.</p>
    <h6>Luck Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#entropic-shield">Entropic
Shield</a>:</td>
          <td> Ranged attacks against you have 20% miss chance.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#aid">Aid</a>:</td>
          <td> +1 on attack rolls, +1 against fear, 1d8 temporary hp
+1/level (max +10).</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-energy">Protection
from Energy</a>:</td>
          <td> Absorb 12 points/level of damage from one kind of
energy.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#freedom-of-movement">Freedom
of Movement</a>:</td>
          <td> Subject moves normally despite impediments.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#break-enchantment">Break
Enchantment</a>:</td>
          <td> Frees subjects from enchantments, alterations, curses,
and petrification.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mislead">Mislead</a>:</td>
          <td> Turns you invisible and creates illusory double.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spell-turning">Spell Turning</a>:</td>
          <td> Reflect 1d4+6 spell levels back at caster.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#moment-of-prescience">Moment
of Prescience</a>:</td>
          <td> You gain insight bonus on single attack roll, check,
or save.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#miracle">Miracle</a> <sup>X</sup>:</td>
          <td> Requests a deity&rsquo;s intercession.</td>
        </tr>
      </tbody>
    </table>
    <a id="madness-domain"></a><h5><a id="madness"></a>MADNESS DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <p style={{
      "fontWeight": "bold"
    }} className="initial">Granted Power: <span style={{
        "fontWeight": "normal"
      }}>The character gains an Insanity score
equal to half his or her class level. For spellcasting (determining
bonus spells and DCs), the character uses his or her Wisdom score plus
his or her Insanity score in place of Wisdom alone.</span>
      <span style={{
        "fontWeight": "normal"
      }}>For all other purposes, such
as skills and saves, use Wisdom minus Insanity in place of Wisdom. </span>
      <span style={{
        "fontWeight": "normal"
      }}>Once per day, the character
can see and act with the clarity of true madness. Use the
character&rsquo;s Insanity score as a positive rather than a negative
modifier on a single roll involving Wisdom. Choose to use this power
before the roll is made.</span></p>
    <h6>Madness Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <span style={{
              "color": "rgb(0, 0, 0)"
            }}>Random Action</span>
          </td>
          <td><span style={{
              "fontStyle": "italic"
            }}>???!!!???</span></td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#touch-of-madness">Touch of Madness</a></td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#dazed">Dazes</a> touched target for
1 round per caster level.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#rage">Rage</a></td>
          <td>Blood frenzy grants target +4 Str, +4 Con and +2 on
Will saves.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#confusion">Confusion</a></td>
          <td>Subjects behave oddly for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#bolts-of-bedevilment">Bolts of Bedevilment</a></td>
          <td>One ray attack per round. Affected creatures are <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#dazed">dazed</a> for 1d3 rounds.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#phantasmal-killer">Phantasmal Killer</a></td>
          <td>Fearsome illusion kills subject or deals 3d6 damage.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#insanity">Insanity</a></td>
          <td>Subject suffers continuous confusion.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#maddening-scream">Maddening Scream</a></td>
          <td>Makes subject race about caterwauling.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#weird">Weird</a></td>
          <td>As phantasmal killer, but affects all within 30 ft.</td>
        </tr>
      </tbody>
    </table>
    <a id="magic"></a><a id="magic-domain"></a><h5>MAGIC DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: Use scrolls, wands, and
other devices with spell completion or spell trigger activation as a
wizard of one-half your cleric level (at least 1st level). For the
purpose of using a scroll or other magic device, if you are also a
wizard, actual wizard levels and these effective wizard levels stack.</p>
    <h6>Magic Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-aura">Magic Aura</a>:</td>
          <td> Alters object&rsquo;s magic aura.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#identify">Identify</a>:</td>
          <td> Determines properties of magic item.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dispel-magic">Dispel Magic</a>:</td>
          <td> Cancels magical spells and effects.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#imbue-with-spell-ability">Imbue
with Spell Ability</a>:</td>
          <td> Transfer spells to subject.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spell-resistance">Spell
Resistance</a>:</td>
          <td> Subject gains SR 12 + level.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#antimagic-field">Antimagic
Field</a>:</td>
          <td> Negates magic within 10 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spell-turning">Spell Turning</a>:</td>
          <td> Reflect 1d4+6 spell levels back at caster.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-spells">Protection
from Spells</a><sup> M F</sup>:</td>
          <td> Confers +8 resistance bonus.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mages-disjunction">Mage&rsquo;s
Disjunction</a>:</td>
          <td> Dispels magic, disenchants magic items.</td>
        </tr>
      </tbody>
    </table>
    <a id="nobility-domain"></a><h5><a id="nobility"></a>NOBILITY DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>The
character has the spell-like ability to inspire allies, giving them a
+2 morale bonus on saving throws, attack rolls, ability checks, skill
checks, and weapon damage rolls. Allies must be able to hear the
character speak for 1 round. Using this ability is a standard action.
It lasts a number of rounds equal to the character&rsquo;s Charisma
bonus and can be used once per day.<span style={{
      "fontWeight": "bold"
    }}></span>
    <h6>Nobility Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#divine-favor">Divine
Favor</a>
          </td>
          <td>You gain +1 per three levels on attack and damage rolls.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#enthrall">Enthrall</a></td>
          <td>Captivates all within 100 ft. + 10 ft./level.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-vestment">Magic Vestment</a></td>
          <td>Armor or shield gains +1 enhancement per four levels.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#discern-lies">Discern Lies</a></td>
          <td>Reveals deliberate falsehoods.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#greater-command">Greater Command</a></td>
          <td>As command, but affects one subject/level.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#geas-quest">Geas/Quest</a></td>
          <td>As lesser geas, plus it affects any creature.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repulsion">Repulsion</a></td>
          <td>Creatures can&rsquo;t approach you.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#demand">Demand</a></td>
          <td>As sending, plus you can send suggestion.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#storm-of-vengeance">Storm of Vengeance</a></td>
          <td>Storm rains acid, lightning, and hail.</td>
        </tr>
      </tbody>
    </table>
    <a id="plant"></a><a id="plant-domain"></a><h5>PLANT DOMAIN</h5>
    <p className="initial"><b>Granted Powers</b>: Rebuke or command plant
creatures as an evil cleric rebukes or commands undead. Use this
ability a total number of times per day equal to 3 + your Charisma
modifier. This granted power is a supernatural ability.</p>
    <p>Add Knowledge (nature) to your list of cleric class skills.</p>
    <h6>Plant Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#entangle">Entangle</a>:</td>
          <td> Plants entangle everyone in 40-ft.-radius.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#barkskin">Barkskin</a>:</td>
          <td> Grants +2 (or higher) enhancement to natural armor.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#plant-growth">Plant Growth</a>:</td>
          <td> Grows vegetation, improves crops.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#command-plants">Command Plants</a>:</td>
          <td> Sway the actions of one or more plant creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wall-of-thorns">Wall of
Thorns</a>:</td>
          <td> Thorns damage anyone who tries to pass.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repel-wood">Repel Wood</a>:</td>
          <td> Pushes away wooden objects.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animate-plants">Animate
Plants</a>:</td>
          <td> One or more trees animate and fight for you.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-plants">Control Plants</a>:</td>
          <td> Control actions of one or more plant creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shambler">Shambler</a>:</td>
          <td> Summons 1d4+2 shambling mounds to fight for you.</td>
        </tr>
      </tbody>
    </table>
    <a id="protection"></a><a id="protection-domain"></a><h5>PROTECTION DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: You can generate a
protective ward as a supernatural ability. Grant someone you touch a
resistance bonus equal to your cleric level on his or her next saving
throw. Activating this power is a standard action. The protective ward
is an abjuration effect with a duration of 1 hour that is usable once
per day.</p>
    <h6>Protection Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sanctuary">Sanctuary</a>:</td>
          <td> Opponents can&rsquo;t attack you, and you can&rsquo;t
attack.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shield-other">Shield Other</a><sup>
F</sup>:</td>
          <td> You take half of subject&rsquo;s damage.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#protection-from-energy">Protection
from Energy</a>:</td>
          <td> Absorb 12 points/level of damage from one kind of
energy.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spell-immunity">Spell Immunity</a>:</td>
          <td> Subject is immune to one spell per four levels.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spell-resistance">Spell
Resistance</a>:</td>
          <td> Subject gains SR 12 + level.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#antimagic-field">Antimagic
Field</a>:</td>
          <td> Negates magic within 10 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#repulsion">Repulsion</a>:</td>
          <td> Creatures can&rsquo;t approach you.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mind-blank">Mind Blank</a>:</td>
          <td> Subject is immune to mental/emotional magic and
scrying.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prismatic-sphere">Prismatic
Sphere</a>:</td>
          <td> As prismatic wall, but surrounds on all sides.</td>
        </tr>
      </tbody>
    </table>
    <a id="repose-domain"></a><h5><a id="repose"></a>REPOSE DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
The Repose domain is similar to the Death domain, but is granted by
good-aligned deities whose clerics are barred from casting evil spells.<span style={{
      "fontWeight": "bold"
    }}></span>
    <p><span style={{
        "fontWeight": "bold"
      }}>Granted Power: </span>The
character may use a death touch once per day. The death touch is a
spell-like ability that is a death effect. The character must succeed
at a melee touch attack against a living creature (using the rules for
touch spells). When the character touches, roll 1d6 per his or her
cleric level. If the total at least equals the creature&rsquo;s current
hit points, it dies.<span style={{
        "fontWeight": "bold"
      }}></span></p>
    <h6>Repose Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#deathwatch">Deathwatch</a>
          </td>
          <td>Reveals how near death subjects within 30 ft. are.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#gentle-repose">Gentle Repose</a></td>
          <td>Preserves one corpse.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#speak-with-dead">Speak with Dead</a></td>
          <td>Corpse answers one question/two levels.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#death-ward">Death Ward</a></td>
          <td>Grants immunity to death spells and negative energy
effects.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#slay-living">Slay Living</a></td>
          <td>Touch attack kills subject.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#undeath-to-death">Undeath to Death</a></td>
          <td>Slays 1d4 HD of undead creatures per caster level (max
20d4).</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#destruction">Destruction</a></td>
          <td>Kills subject and destroys remains.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="divineNewSpells.html#surelife">Surelife</a></td>
          <td>Protects caster from specified natural death.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#wail-of-the-banshee">Wail of the Banshee</a></td>
          <td>Kills one creature/level.</td>
        </tr>
      </tbody>
    </table>
    <a id="rune-domain"></a><h5><a id="rune"></a>RUNE DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power:</span> Free
Scribe Scroll feat.<span style={{
      "fontWeight": "bold"
    }}></span>
    <h6>Rune Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#erase">Erase</a>
          </td>
          <td>Mundane or magical writing vanishes.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#secret-page">Secret Page</a></td>
          <td>Changes one page to hide its real content.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#glyph-of-warding">Glyph of Warding</a></td>
          <td>Inscription harms those who pass it.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#explosive-runes">Explosive Runes</a></td>
          <td>Deals 6d6 damage when read.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#lesser-planar-binding">Lesser Planar Binding</a></td>
          <td>Traps extraplanar creature of 6 HD or less until it
performs a task.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#greater-glyph-of-warding">Greater Glyph of
Warding</a></td>
          <td>As glyph of warding, but up to 10d8 damage or 6th-level
spell.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#instant-summons">Instant Summons</a></td>
          <td>Prepared object appears in your hand.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "#579eb6"
            }} href="spellsS.html#symbol-of-death">Symbol of Death</a></td>
          <td>Triggered rune slays nearby creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#teleportation-circle">Teleportation Circle</a></td>
          <td>Circle teleports any creature inside to designated spot.</td>
        </tr>
      </tbody>
    </table>
    <a id="skalykind-domain"></a><h5><a id="skalykind"></a>SKALYKIND DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <span style={{
      "fontWeight": "bold"
    }}>Granted Power: </span>Rebuke or
command animals (reptilian creatures and snakes only) as an evil cleric
rebukes or commands undead.
Use this ability a total number of times per day equal to 3 + Charisma
modifier.<span style={{
      "fontWeight": "bold"
    }}></span>
    <h6>Skalykind Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-fang">Magic Fang</a>
          </td>
          <td>One natural weapon of subject creature gets +1 on
attack and damage rolls.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-trance">Animal Trance&dagger;</a></td>
          <td>Fascinates 2d6 HD of animals.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#greater-magic-fang">Greater Magic Fang</a></td>
          <td>One natural weapon of subject creature gets +1/four
levels on attack and damage rolls (max +5).</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#poison">Poison</a></td>
          <td>Touch deals 1d10 Con damage, repeats in 1 min.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-growth">Animal Growth&dagger;</a></td>
          <td>One animal/two levels doubles in size.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#eyebite">Eyebite</a></td>
          <td>Target becomes <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#panicked">panicked</a>, <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#sickened">sickened</a>, and comatose.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#creeping-doom">Creeping Doom*</a>
          </td>
          <td>Swarms of centipedes attack at your command.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#animal-shapes">Animal Shapes&dagger;</a></td>
          <td>One ally/level polymorphs into chosen animal.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#shapechange">Shapechange</a></td>
          <td>Transforms you into any creature, and change forms once
per round.</td>
        </tr>
        <tr>
          <td colSpan="2">&dagger;<span style={{
              "fontStyle": "italic"
            }}>Affects
ophidian or reptilian
creatures only.</span></td>
        </tr>
        <tr>
          <td colSpan="2" rowSpan="1">*<span style={{
              "fontStyle": "italic"
            }}>Composed of tiny snakes</span></td>
        </tr>
      </tbody>
    </table>
    <a id="strength"></a><a id="strength-domain"></a><h5>STRENGTH DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: You can perform a feat
of strength as a supernatural ability. You gain an enhancement bonus to
Strength equal to your cleric level. Activating the power is a free
action, the power lasts 1 round, and it is usable once per day.</p>
    <h6>Strength Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#enlarge-person">Enlarge
Person</a>:</td>
          <td> Humanoid creature doubles in size.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#bulls-strength">Bull&rsquo;s
Strength</a>:</td>
          <td> Subject gains +4 to Str for 1 min./level.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-vestment">Magic
Vestment</a>:</td>
          <td> Armor or shield gains +1 enhancement per four levels.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spell-immunity">Spell Immunity</a>:</td>
          <td> Subject is immune to one spell per four levels.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#righteous-might">Righteous
Might</a>:</td>
          <td> Your size increases, and you gain combat bonuses.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#stoneskin">Stoneskin</a><sup> M</sup>:</td>
          <td> Ignore 10 points of damage per attack.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#grasping-hand">Grasping Hand</a>:</td>
          <td> Large hand provides cover, pushes, or <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="specialAttacks.html#grapple">grapples</a>.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#clenched-fist">Clenched Fist</a>:</td>
          <td> Large hand provides cover, pushes, or attacks your
foes.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#crushing-hand">Crushing Hand</a>:</td>
          <td> Large hand provides cover, pushes, or crushes your
foes.</td>
        </tr>
      </tbody>
    </table>
    <a id="sun"></a><a id="sun-domain"></a><h5>SUN DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: Once per day, you can
perform a greater turning against undead in place of a regular turning.
The greater turning is like a normal turning except that the undead
creatures that would be turned are destroyed instead.</p>
    <h6>Sun Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#endure-elements">Endure
Elements</a>:</td>
          <td> Exist comfortably in hot or cold environments.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#heat-metal">Heat Metal</a>:</td>
          <td> Make metal so hot it damages those who touch it.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#searing-light">Searing Light</a>:</td>
          <td> Ray deals 1d8/two levels, more against undead.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-shield">Fire Shield</a>:</td>
          <td> Creatures attacking you take fire damage; you&rsquo;re
protected from heat or cold.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flame-strike">Flame Strike</a>:</td>
          <td> Smite foes with divine fire (1d6/level damage).</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fire-seeds">Fire Seeds</a>:</td>
          <td> Acorns and berries become grenades and bombs.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sunbeam">Sunbeam</a>:</td>
          <td> Beam <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded">blinds</a> and deals 4d6 damage.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sunburst">Sunburst</a>:</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded"> Blinds</a> all within 10 ft., deals
6d6 damage.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#prismatic-sphere">Prismatic
Sphere</a>:</td>
          <td> As prismatic wall, but surrounds on all sides.</td>
        </tr>
      </tbody>
    </table>
    <a id="travel"></a><a id="travel-domain"></a><h5>TRAVEL DOMAIN</h5>
    <p className="initial"><b>Granted Powers</b>: For a total time per
day of 1 round per cleric level you possess, you can act normally
regardless of magical effects that impede movement as if you were
affected by the spell freedom of movement. This effect occurs
automatically as soon as it applies, lasts until it runs out or is no
longer needed, and can operate multiple times per day (up to the total
daily limit of rounds).</p>
    <p>This granted power is a supernatural ability.</p>
    <p>Add Survival to your list of cleric class skills.</p>
    <h6>Travel Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#longstrider">Longstrider</a>:</td>
          <td> Increases your speed.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#locate-object">Locate Object</a>:</td>
          <td> Senses direction toward object (specific or type).</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fly">Fly</a>:</td>
          <td> Subject flies at speed of 60 ft.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#dimension-door">Dimension
Door</a>:</td>
          <td> Teleports you short distance.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#teleport">Teleport</a>:</td>
          <td> Instantly transports you as far as 100 miles/level.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#find-the-path">Find the Path</a>:</td>
          <td> Shows most direct way to a location.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#greater-teleport">Teleport,
Greater</a>:</td>
          <td> As teleport, but no range limit and no off-target
arrival.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#phase-door">Phase Door</a>:</td>
          <td> Creates an invisible passage through wood or stone.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#astral-projection">Astral
Projection</a><sup> M</sup>:</td>
          <td> Projects you and companions onto Astral Plane.</td>
        </tr>
      </tbody>
    </table>
    <a id="trickery"></a><a id="trickery-domain"></a><h5>TRICKERY DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: Add <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="skillsAll.html#bluff">Bluff</a>, <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="SkillsAll.html#Disguise">Disguise</a>,
and <a style={{
        "color": "rgb(87, 158, 182)"
      }} href="SkillsAll.html#Hide">Hide</a> to your list
of cleric class skills.</p>
    <h6>Trickery Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#disguise-self">Disguise Self</a>:</td>
          <td> Disguise own appearance.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#invisibility">Invisibility</a>:</td>
          <td> Subject <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> 1 min./level or until
it attacks.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#nondetection">Nondetection</a><sup>
M</sup>:</td>
          <td> Hides subject from divination, scrying.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#confusion">Confusion</a>:</td>
          <td> Subjects behave oddly for 1 round/level.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#false-vision">False Vision</a><sup>
M</sup>:</td>
          <td> Fools scrying with an illusion.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#mislead">Mislead</a>:</td>
          <td> Turns you <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#invisible">invisible</a> and creates illusory
double.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#screen">Screen</a>:</td>
          <td> Illusion hides area from vision, scrying.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#polymorph-any-object">Polymorph
Any Object</a>:</td>
          <td> Changes any subject into anything else.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#time-stop">Time Stop</a>:</td>
          <td> You act freely for 1d4+1 rounds.</td>
        </tr>
      </tbody>
    </table>
    <a id="war"></a><a id="war-domain"></a><h5>WAR DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: Free Martial Weapon
Proficiency with deity&rsquo;s favored weapon (if necessary) and Weapon
Focus
with the deity&rsquo;s favored weapon.</p>
    <h6>War Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-weapon">Magic Weapon</a>:</td>
          <td> Weapon gains +1 bonus.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#spiritual-weapon">Spiritual
Weapon</a>:</td>
          <td> Magical weapon attacks on its own.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#magic-vestment">Magic
Vestment</a>:</td>
          <td> Armor or shield gains +1 enhancement per four levels.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#divine-power">Divine Power</a>:</td>
          <td> You gain attack bonus, +6 to Str, and 1 hp/level.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#flame-strike">Flame Strike</a>:</td>
          <td> Smite foes with divine fire (1d6/level damage).</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#blade-barrier">Blade Barrier</a>:</td>
          <td> Wall of blades deals 1d6/level damage.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#power-word-blind">Power
Word Blind</a>:</td>
          <td><a style={{
              "color": "rgb(87, 158, 182)"
            }} href="abilitiesAndConditions.html#blinded"> Blinds</a> creature with 200 hp or
less.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#power-word-stun">Power Word
Stun</a>:</td>
          <td> Stuns creature with 150 hp or less.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsPtoR.html#power-word-kill">Power Word
Kill</a>:</td>
          <td> Kills creature with 100 hp or less.</td>
        </tr>
      </tbody>
    </table>
    <a id="water"></a><a id="water-domain"></a><h5>WATER DOMAIN</h5>
    <p className="initial"><b>Granted Power</b>: Turn or destroy fire
creatures as a good cleric turns undead. Rebuke, command, or bolster
water creatures as an evil cleric rebukes undead. Use these abilities a
total number of times per day equal to 3 + your Charisma modifier. This
granted power is a supernatural ability.</p>
    <h6>Water Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscuring-mist">Obscuring
Mist</a>:</td>
          <td> Fog surrounds you.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fog-cloud">Fog Cloud</a>:</td>
          <td> Fog obscures vision.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#water-breathing">Water
Breathing</a>:</td>
          <td> Subjects can breathe underwater.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-water">Control Water</a>:</td>
          <td> Raises or lowers bodies of water.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#ice-storm">Ice Storm</a>:</td>
          <td> Hail deals 5d6 damage in cylinder 40 ft. across.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#cone-of-cold">Cone of Cold</a>:</td>
          <td> 1d6/level cold damage.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsAtoB.html#acid-fog">Acid Fog</a>:</td>
          <td> Fog deals acid damage.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsHtoL.html#horrid-wilting">Horrid
Wilting</a>:</td>
          <td> Deals 1d6/level damage within 30 ft.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsDtoE.html#elemental-swarm">Elemental
Swarm</a>*:</td>
          <td> Summons multiple elementals.</td>
        </tr>
        <tr>
          <td colSpan="2">* <i>Cast as a water spell only.</i></td>
        </tr>
      </tbody>
    </table>
    <a id="weather-domain"></a><h5><a id="weather"></a>WEATHER DOMAIN</h5>
    <b>Note: Non-Core, From <a style={{
        "color": "#579eb6"
      }} href="divine.html">Divine Section</a></b>
    <p className="initial"><b>Granted Power: </b>Survival is a class
skill.<b></b></p>
    <h6>Weather Domain Spells</h6>
    <table className="spell-list" border="0" cellPadding="1" cellSpacing="0" style={{
      "width": "75%"
    }}>
      <tbody>
        <tr>
          <th style={{
            "width": "25%"
          }}>Spell Name</th>
          <th style={{
            "width": "75%"
          }}>Brief Description</th>
        </tr>
        <tr className="odd-row">
          <td>1 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsMtoO.html#obscuring-mist">Obscuring Mist</a>
          </td>
          <td>Fog surrounds you.</td>
        </tr>
        <tr>
          <td>2 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsFtoG.html#fog-cloud">Fog Cloud</a></td>
          <td>Fog obscures vision.</td>
        </tr>
        <tr className="odd-row">
          <td>3 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#call-lightning">Call Lightning</a></td>
          <td>Calls down lightning bolts (3d6 per bolt) from sky.</td>
        </tr>
        <tr>
          <td>4 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#sleet-storm">Sleet Storm</a></td>
          <td>Hampers vision and movement.</td>
        </tr>
        <tr className="odd-row">
          <td>5 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#call-lightning-storm">Call Lightning Storm</a></td>
          <td>As call lightning, but 5d6 damage per bolt.</td>
        </tr>
        <tr>
          <td>6 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-winds">Control Winds</a></td>
          <td>Change wind direction and speed.</td>
        </tr>
        <tr className="odd-row">
          <td>7 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsC.html#control-weather">Control Weather</a></td>
          <td>Changes weather in local area.</td>
        </tr>
        <tr>
          <td>8 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsTtoZ.html#whirlwind">Whirlwind</a></td>
          <td>Cyclone deals damage and can pick up creatures.</td>
        </tr>
        <tr className="odd-row">
          <td>9 <a style={{
              "color": "rgb(87, 158, 182)"
            }} href="spellsS.html#storm-of-vengeance">Storm of Vengeance</a></td>
          <td>Storm rains acid, lightning, and hail.</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      